import React, { useState } from 'react'
import styled from 'styled-components'
import { BsChevronBarExpand } from 'react-icons/bs'
import Image from 'next/image'
import Link from 'next/link'
import { socialEntries } from '@/constants/socials'
import ServiceStatus from '@/components/ServiceStatus'

export default function Footer() {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleFooter = () => setIsExpanded(!isExpanded)

  const socials = socialEntries.map((e) => (
    <Link
      className="btn-ghost tooltip tooltip-left btn-xs btn-circle btn"
      key={e.href}
      href={e.href}
      data-tip={e.label}
      target="_blank"
    >
      {e.icon}
    </Link>
  ))

  return (
    <Style className={`hidden flex-col transition-all lg:flex ${isExpanded ? 'expanded' : ''}`}>
      <div className="mask invisible flex-auto cursor-pointer transition-all" onClick={toggleFooter} />

      <div className={`footer-content hidden transition-all ${isExpanded ? 'expanded' : ''}`}>
        <footer className="top-footer footer items-center bg-neutral px-4 pt-3 text-neutral-content">
          <div className="grid-flow-col items-center gap-4">
            <ServiceStatus className="flex items-center" />
          </div>

          <div className="grid-flow-col gap-6 place-self-center justify-self-end">
            <div className="flex items-center gap-3">{socials}</div>

            <button className="btn-ghost btn-xs btn gap-2" onClick={toggleFooter}>
              {isExpanded ? 'Collapse' : 'Expand'}
              <BsChevronBarExpand fontSize={20} />
            </button>
          </div>
        </footer>

        <footer className="footer flex-auto bg-neutral px-10 pt-7 text-neutral-content">
          <div className="flex flex-col gap-0">
            <div className="flex gap-2">
              <Link className="link-hover link" href="https://legal.deepspace.game/cookie-policy">
                Cookie Policy
              </Link>
              <Link className="link-hover link" href="https://legal.deepspace.game/privacy-policy">
                Privacy Policy
              </Link>
              <Link className="link-hover link" href="https://legal.deepspace.game/terms-conditions">
                Terms & Conditions
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </Style>
  )
}

const Style = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 50;

  &.expanded {
    top: 0;

    .mask {
      background-color: rgba(21, 25, 30, 0.4);
      visibility: visible;
    }
  }

  .footer-content {
    display: flex;
    flex-direction: column;
    height: 56px;
    overflow: hidden;
    background: url('/images/ui/header_wallpaper.webp') center bottom;
    background-size: cover;

    &.expanded {
      height: 180px;
    }
  }

  .footer {
    background-color: #0d0b10cc;

    &.top-footer {
      border-top: 1px solid #351268;
    }
  }
`
