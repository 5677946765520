import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import EthIcon from '../Svg/Icons/EthIcon'
import BnbIcon from '../Svg/Icons/BnbIcon'
import { navigationEntries } from '@/constants/navigation'
import { socialEntries } from '@/constants/socials'
import Image from 'next/image'
import ServiceStatus from '@/components/ServiceStatus'

export default function Drawer() {
  const getChainEntries = (chain: string | null) =>
    navigationEntries
      .filter((e) => e.chain === chain)
      .map((e) => (
        <li key={e.href}>
          <Link href={e.href} className={`${e.isActive ? 'active' : ''}`}>
            {e.label}
          </Link>
        </li>
      ))

  const secondaryEntries = navigationEntries
    .filter((e) => !e.chain)
    .map((e) => (
      <li key={e.href}>
        <Link href={e.href} className={`btn-outline mb-1 btn ${e.isActive ? 'active' : ''}`}>
          {e.icon}
          {e.label}
        </Link>
      </li>
    ))

  const socials = socialEntries.map((e) => (
    <Link
      className="btn btn-ghost tooltip btn-xs btn-circle"
      key={e.href}
      href={e.href}
      data-tip={e.label}
      target="_blank"
    >
      {e.icon}
    </Link>
  ))

  return (
    <Style className="drawer-side">
      <label htmlFor="drawer" className="drawer-overlay"></label>
      <div className="flex w-80 flex-col bg-base-100">
        <Image className="m-auto pt-4" src="/images/icons/deepspace.svg" alt="DEEPSPACE" width={250} height={40} />

        <ul className="menu px-4 pt-5">
          <div className="divider mt-0 mb-3">
            <EthIcon width="16px" />
          </div>
          {getChainEntries('ETH')}
          <div className="divider mt-10 mb-3">
            <BnbIcon width="19px" />
          </div>
          {getChainEntries('BNB')}
          <div className="divider my-5" />
          <div className="flex justify-evenly" style={{flexDirection: 'column'}}>{secondaryEntries}</div>
        </ul>

        <div className="flex flex-auto flex-col justify-between px-4 py-5 text-sm">
          <div className="flex flex-col">
            <div className="mb-4 flex items-center justify-between">
              <ServiceStatus />
            </div>

            <div className="flex items-center justify-evenly gap-3">{socials}</div>
          </div>

          <div className="mt-10 flex flex-col items-center gap-0">
            <div className="flex flex-wrap justify-center gap-x-2 text-xs">
              <Link className="link-hover link" href="https://legal.deepspace.game/cookie-policy">
                Cookie Policy
              </Link>
              <Link className="link-hover link" href="https://legal.deepspace.game/privacy-policy">
                Privacy Policy
              </Link>
              <Link className="link-hover link" href="https://legal.deepspace.game/terms-conditions">
                Terms & Conditions
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Style>
  )
}

const Style = styled.div``
